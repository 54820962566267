import './App.css';
import MainStepForm from './components/MainStepForm';
import logo from './horizontal_logo.png';//'./brand-logo-white.png';
import badge1 from './hrsa-badge-2022.png';
import badge2 from './pcmh-badge-2022.png';
import logoMobile from './brand-logo-white.png';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';


/** our entry point for our react app
 * this app uses tailwindcss which is why you see very little
 * included css.  just classes
 * find info here https://tailwindcss.com/docs/installation
 */

function App() {
  useEffect(()=>{
    ReactGA.initialize('G-6364FWMN9B');
    ReactGA.send({ hitType: "pageview", page: "/self-scheduling-tool", title: "Self Scheduling | CHAS Health " });
  }, []);
  
  //jsx return
  return (
    <div className="flex flex-col h-screen justify-center text-center overflow-hidden text-chas-slate">
      <header className="fixed top-0 left-0 right-0 z-10 bg-chas-green p-4 flex justify-between px-9">
        <img src={logo} className="h-[50px] hidden md:block" alt='white CHAS Health logo'/>
        <img src={logoMobile} className="h-[50px] block md:hidden" alt='white CHAS Health logo'/>
        <h1 className="text-3xl text-white mt-[10px]">
          Self Scheduling
        </h1>
      </header>
      <main className='grow overflow-hidden mt-[82px] pb-[120px]'>
        <MainStepForm />
      </main>
      <footer className="fixed bottom-0 left-0 right-0 z-10 p-4">
        <div className="text-center text-white z-10 text-xs sm:px-4 px-0 gap-2 flex items-center justify-center flex-wrap">
          <a href='https://chas.org/for-patients/patient-centered-medical-home/'><img src={badge1} className="h-[40px] w-[40px] inline" alt='white CHAS Health logo'/></a>
          <a href='https://chas.org/for-patients/patient-centered-medical-home/'><img src={badge2} className="h-[40px] w-[40px] inline" alt='white CHAS Health logo'/></a>
          <p className='inline'>Copyright ©2023 CHAS Health | All rights reserved.</p>
          <p className='inline'>CHAS is a 501(c)3 nonprofit organization. EIN 91-1641797</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
